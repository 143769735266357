.headline {
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.mainButton {
  margin-top: 20px;
}

.mainForm {
  display: grid;
  grid-template-columns: 90% 1fr;
}

.tabFrame {
  max-width: 720px;
  margin: 0 auto;
  border: 1px solid lightgray;
}

.tabs {
  border-bottom: 1px solid lightgray;
}

@media (max-width: 740px) {
  .tabFrame {
    max-width: 500px;
  }
}

@media (max-width:520px) {
  .tabFrame {
    max-width: 310px;
  }
}